import { create } from "zustand";

import { IChatList, IMessage } from "../types/chat";

interface IWebsocketChatStore {
  rooms: IChatList,
  setRooms: (newRooms: IChatList) => void,
  updateUnreadMessages: (roomId: string, unreadMessages: number) => void;
  roomId: string;
  setRoomId: (newRoomId: string) => void;
  messages: IMessage[];
  setMessages: (newMessages: IMessage[]) => void;
  markMessageRead: (messageId: string) => void;
  resetStore: () => void;
  page: number;
  setPage: (newPage: number | ((prevPage: number) => number)) => void;
  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;
  loadMoreMessages: (newMessages: IMessage[], hasMore: boolean) => void;
  refresh: boolean;
  setRefresh: (newRefresh: boolean) => void;
}

const initialState = {
  rooms: [],
  roomId: '',
  messages: [],
  page: 1,
  hasMore: true,
  refresh: true,
}

const useWebsocketChatStore = create<IWebsocketChatStore>((set) => ({
  rooms: [],
  setRooms: (newRooms) => set(() => ({
    rooms: newRooms,
  })),
  updateUnreadMessages: (roomId, unreadMessages) => set((prevState) => ({
    rooms: prevState.rooms.map((room) =>
      room.id === roomId ? { ...room, unreadMessages } : room
    ),
  })),
  roomId: '',
  setRoomId: (newRoomId) => set((prevState) => ({
    roomId: newRoomId,
    ...(prevState.roomId !== newRoomId && {messages: [], page: 1, hasMore: true, message: '' })
  })),
  messages: [],
  setMessages: (newMessages, reset = false) => set((prevState) => ({
    messages: reset ? newMessages : [...prevState.messages, ...newMessages.filter(
      (newMsg) => !prevState.messages.some((oldMsg) => oldMsg.id === newMsg.id)
    )],
  })),
  markMessageRead: (messageId) => set((prevState) => ({
    messages: prevState.messages.map((msg) =>
      msg.id === messageId ? { ...msg, read: true } : msg
    )
  })),
  resetStore: () => set (() => initialState),
  page: 1,
  setPage: (newPage) => set((prevState) => ({
    page: typeof newPage === 'function' ? newPage(prevState.page) : newPage
  })),
  hasMore: true,
  setHasMore: (newHasMore) => set({ hasMore: newHasMore }),
  loadMoreMessages: (newMessages: IMessage[], hasMore: boolean) => set((prevState) => ({
    messages: [...prevState.messages, ...newMessages.filter(
      (newMsg) => !prevState.messages.some((oldMsg) => oldMsg.id === newMsg.id)
    )],
    hasMore
  })),
  refresh: true,
  setRefresh: (newRefresh) => set({ refresh: newRefresh })
}));

export default useWebsocketChatStore;
