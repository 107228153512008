import { useRef, useCallback } from 'react';
import io, { Socket } from 'socket.io-client';

import { IMessage } from "../types/chat";

import useAuthStore from "../../../common/stores/useAuthStore";

import useWebsocketChatStore from "../stores/useWebsocketChatStore";

interface UseWebSocketChatReturn {
  connect: () => void;
  disconnect: () => void;
  sendMessage: (message: string) => void;
  handleMessageRead: (messageId: string) => void;
}

const useWebSocketChat = (): UseWebSocketChatReturn => {
  const socketRef = useRef<Socket | null>(null);

  const { roomId, markMessageRead } = useWebsocketChatStore()
  const { accessToken: token } = useAuthStore((state) => state);

  const connect = useCallback(() => {
    if (socketRef.current?.connected || !token) return;

    const socket = io('https://api-dev.reelty.cz/chat', { auth: { token } });
    socketRef.current = socket;

    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on('connect', () => {
      console.log('Connected');
      socket.emit('auth');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected');
    });

    socket.on('error', (error: IMessage) => {
      console.error('Error:', error);
    });

    socket.on('message', (payload: any) => {
      console.log('[ON_MESSAGE]:', payload);

      const { roomId, setMessages, updateUnreadMessages } = useWebsocketChatStore.getState();
      const messageRoomId = payload?.room?.id;

      if (messageRoomId === roomId) {
        setMessages([payload]);
      }

      const room = payload?.room;
      if (room) {
        updateUnreadMessages(room.id, room.unreadMessages)
      }
    });

    socket.on('readMessages', (payload: { read: { messageId: string, userId: string }[] }) => {
      const { messages, markMessageRead } = useWebsocketChatStore.getState();
      console.log('[READ_MESSAGES]:', payload);
      payload.read.forEach(readMsg => {
        const message = messages.find(msg => msg.id === readMsg.messageId);

        if (message) {
          markMessageRead(readMsg.messageId);
        }
      });
    });
  }, [token]);

  const disconnect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      console.log('WebSocket disconnected');
    }
  }, []);

  const sendMessage = useCallback((message: string) => {
    if (socketRef.current && message) {
      socketRef.current.emit('message', { roomId, body: message });
    }
  }, [socketRef, roomId]);

  const handleMessageRead = useCallback((messageId: string) => {
    if (socketRef.current && messageId) {
      socketRef.current.emit('readMessages', { messageIds: [messageId], roomId });
      markMessageRead(messageId);
    }
  }, [socketRef, markMessageRead, roomId]);

  return {
    connect,
    disconnect,
    sendMessage,
    handleMessageRead,
  };
};

export default useWebSocketChat;
