import { useEffect } from "react";

import useGlobalErrorStore from "../../stores/useGlobalErrorStore";

const TIME = 5000

const Notification = () => {
    const { error, setError } = useGlobalErrorStore()

    useEffect(() => {
        let timer: any

        if (!timer && error.length) {
            timer = setTimeout(() => {
                setError([]);
                clearTimeout(timer);
            }, TIME)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [error])

    return (<div className={`fixed bottom-[40px] left-1/2 transform -translate-x-1/2 grid gap-3 z-[5000] ${!error.length && 'hidden'}`}>
        {Array.isArray(error)
            ?
            error.map((message, index) => (
            <div key={message + index} className="flex-center min-w-[358px] h-[42px] bg-[#fbebeb] rounded-lg text-[15px] text-[#000]">
            {message}
            </div>
            ))
            : (
                <div className="flex-center min-w-[358px] h-[42px] bg-[#fbebeb] rounded-lg text-[15px] text-[#000]">
                    {error}
                </div>
            )
        }
    </div>)
}

export default Notification
