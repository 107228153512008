import React from "react";

import { getAddressString } from "../../../common/utils/address";

import { IChatProperty } from "../types/chat";

import PropertyStatus from "../../property/components/PropertyStatus/PropertyStatus";

import LocationIcon from "../../../common/assets/icons/LocationIcon";
import NoImageIcon from "../../../common/assets/icons/NoImageIcon";
import EyeIcon from "../../../common/assets/icons/EyeIcon";
import PhoneIcon from "../../../common/assets/icons/PhoneIcon";
import MessagesIcon from "../../../common/assets/icons/menu/MessagesIcon";
import HeartIcon from "../../../common/assets/icons/HeartIcon";

interface ChatPropertyProps {
  currentRoomProperty: IChatProperty;
}

const ChatProperty: React.FC<ChatPropertyProps> = ({ currentRoomProperty }) => {
  return (
    <div className="relative w-[329px]">
      {currentRoomProperty?.status && (
        <div className="absolute top-[10px] right-[10px]">
          <PropertyStatus status={currentRoomProperty.status}/>
        </div>
      )}
      {currentRoomProperty?.mainImage?.url ? (
        <img
          className="aspect-[1.75] object-cover w-[329px] rounded-[10px]"
          src={currentRoomProperty.mainImage.url}
          alt="property photo"/>
      ) : (
        <div className="flex-center w-[329px] aspect-[1.75] bg-[#f8f8f8] rounded-[10px]">
          <NoImageIcon width={40} height={40} />
        </div>
      )}
      {/*<div className="font-Manrope text-[12px] text-[#666666] flex items-center gap-4 mt-[4px] text-[#666] text-[12px]">*/}
      {/*  <div className="flex items-center gap-[6px]">*/}
      {/*    <EyeIcon />*/}
      {/*    <div>1 000</div>*/}
      {/*  </div>*/}
      {/*  <div className="flex items-center gap-[6px]">*/}
      {/*    <PhoneIcon />*/}
      {/*    <div>32</div>*/}
      {/*  </div>*/}
      {/*  <div className="flex items-center gap-[6px]">*/}
      {/*    <MessagesIcon/>*/}
      {/*    <div>23</div>*/}
      {/*  </div>*/}
      {/*  <div className="flex items-center gap-[6px]">*/}
      {/*    <HeartIcon/>*/}
      {/*    <div>24</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="flex justify-between items-center mt-1 gap-2 font-Manrope">
        <div className="flex flex-1 items-center gap-[6px] min-w-0">
          <div className="w-[18px]">
            <LocationIcon width={18} height={18}/>
          </div>
          <div className="w-auto truncate text-[14px] text-[#333333]">
            {currentRoomProperty?.address && getAddressString(currentRoomProperty?.address) || 'Unknown'}
          </div>
        </div>
        <div>
          <div
            className="flex-center whitespace-nowrap h-[30px] bg-[#006CFB0D] text-[12px] font-semibold text-[#333333] px-2">
            {!!currentRoomProperty?.price ? `Kč ${currentRoomProperty.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}` : 0}
            <span>{currentRoomProperty?.priceType === 'perMonth' ? '/mo' : currentRoomProperty?.priceType === 'perDay' ? '/day' : ''}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatProperty;
