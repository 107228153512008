import React from "react";
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom"

import BaseLayout from "../pages/base/BaseLayout";
// import DashboardPage from "../pages/base/DashboardPage";
import PropertiesPage from "../pages/base/PropertiesPage";
import BillingPage from "../pages/base/BillingPage";
import MessagesPage from "../pages/base/MessagesPage";
import AccountPage from "../pages/base/AccountPage";
import SupportPage from "../pages/base/SupportPage";
import PropertyPage from "../pages/base/PropertyPage";
import PropertyCreatePage from "../pages/base/PropertyCreatePage";
import PropertyEditPage from "../pages/base/PropertyEditPage";
import NotFoundPage from "../pages/NotFoundPage";

const routesAgent: RouteObject[] = [
  {
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/properties" />,
        // element: <DashboardPage />,
      },
      {
        path: "/properties",
        element: <PropertiesPage />,
      },
      {
        path: "/property/:id",
        element: <PropertyPage />,
      },
      {
        path: "/property/create",
        element: <PropertyCreatePage />,
      },
      {
        path: "/property/:id/edit",
        element: <PropertyEditPage />,
      },
      {
        path: "/billing",
        element: <BillingPage />,
      },
      {
        path: "/messages",
        element: <MessagesPage />,
      },
      {
        path: "/account",
        element: <AccountPage />,
      },
      // {
      //   path: "/support",
      //   element: <SupportPage />,
      // },
      {
        path: "*",
        element: <NotFoundPage/>
      },
    ]
  },
]

export default routesAgent;
