import React, { FC, useMemo } from "react";
import classNames from "classnames";

import { PropertyStatus as PropertyStatusType } from "../../../../common/types/dto/property";

import styles from "./PropertyStatus.module.scss";

interface IPropertyStatusProps {
  status?: PropertyStatusType;
}

const PropertyStatus: FC<IPropertyStatusProps> = ({ status}) => {
  if (!status) return null;

  const text = useMemo(() => {
    switch (status) {
      case 'DRAFT':
        return 'Draft';
      case 'ACTIVE':
        return 'Active';
      case 'IN_MODERATE':
        return 'Inactive, in moderation';
      case 'UNPAID':
        return 'Inactive, unpaid';
      case 'ARCHIVE':
        return 'Archive';
      case 'REJECTED':
        return 'Rejected';
      default:
        return '';
    }
  }, [status])

  const classNameText = status.replaceAll("_", "-").toLowerCase();
  return (
    <span
      className={
      classNames(
        "h-[24px] min-w-[70px] inline-flex justify-center items-center px-[12px] py-[6px] rounded-[6px] bg-[#48BB78] text-white text-[12px] font-bold",
          styles[`property-status--${classNameText}`]
        )}
    >
      {text}
    </span>);
}

export default PropertyStatus;
