import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import AddImage from "../../../common/assets/icons/AddImage";

interface IAvatarDropzoneProps {
  avatar: any;
  setAvatar: Dispatch<SetStateAction<any>>;
}

const AvatarDropzone: FC<IAvatarDropzoneProps> = ({ avatar, setAvatar }) => {
  const avatarSrc = avatar?.url || avatar?.src;

  const onDrop = useCallback((files: File[]) => {
    if (FileReader && files && files.length) {
        const file = files[0];
        if(file.size >= 1048576) {
          alert(`File "${file.name}" is to big. Allowed files only under 1mb.`);
          return;
        }
        const fr = new FileReader();
        fr.onload = function () {
          setAvatar({file, src: fr.result, toUpload: true});
        }
        fr.readAsDataURL(file);
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp', '.gif'],
    },
  });

  const handleDeleteClick = () => {
    setAvatar({});
  }

  return (
    <div className="relative w-[170px] h-[170px]">
      <div {...getRootProps()} className="flex-center w-[170px] h-[170px] border border-[#eeeeee] rounded-full cursor-pointer">
        <input {...getInputProps()}/>
        <AddImage />
      </div>
      {avatarSrc && (
        <>
          <img
            src={avatarSrc}
            alt="property image"
            className="absolute top-0 left-0 w-full h-full z-[1000] border border-[#eeeeee] overflow-hidden rounded-full object-cover"
          />
          <div
            className="absolute top-[6px] right-[6px] text-[18px] text-[red] font-extrabold group-hover:block cursor-pointer leading-[1] z-[1001]"
            onClick={() => handleDeleteClick()}
          >x</div>
        </>
      )}
    </div>
  );
}

export default AvatarDropzone;
