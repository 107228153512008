import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { PropertyStatus } from "../../../../common/types/dto/property";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import PublishModalForm from "../PublishModalForm/PublishModalForm";
import ArchiveModalForm from "../ArchiveModalForm/ArchiveModalForm";

import EditIcon from "../../../../common/assets/icons/EditIcon";
import CrossIcon from "../../../../common/assets/icons/CrossIcon";
import PublishIcon from "../../../../common/assets/icons/PublishIcon";
import ArchiveIcon from "../../../../common/assets/icons/ArchiveIcon";
import WalletIcon from "../../../../common/assets/icons/WalletIcon";

interface PropertyActionsAgentProps {
  id?: string;
  status: PropertyStatus;
}

const PropertyActionsAgent: React.FC<PropertyActionsAgentProps> = ({ id, status }) => {
  const { setIsOpen, setChildren } = useGlobalModalStore();

  if (!id) return <></>;

  const publishProperty = useCallback(() => {
    setChildren(<PublishModalForm id={id} />)
    setIsOpen(true);
  }, [id]);

  const archiveProperty = useCallback(() => {
    setChildren(<ArchiveModalForm id={id} />)
    setIsOpen(true);
  }, [id]);

  const items = useMemo(() => {
    switch (status) {
      case 'DRAFT':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={publishProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <PublishIcon width={24} height={24} />
            </button>
            <Link to={`/property/${id}/edit`}>
              <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
                <EditIcon/>
              </div>
            </Link>
          </div>
        )
      case 'IN_MODERATE':
        return (
          <div className="flex items-center gap-[10px]">
            <Link to={`/property/${id}/edit`}>
              <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
                <EditIcon/>
              </div>
            </Link>
            <button onClick={archiveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CrossIcon width={24} height={24} color="black" />
            </button>
          </div>
        )
      case 'UNPAID':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={publishProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <WalletIcon width={24} height={24} />
            </button>
            <Link to={`/property/${id}/edit`}>
              <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
                <EditIcon/>
              </div>
            </Link>
          </div>
        )
      case 'ACTIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={archiveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <ArchiveIcon width={24} height={24} color="black" />
            </button>
            <Link to={`/property/${id}/edit`}>
              <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
                <EditIcon/>
              </div>
            </Link>
          </div>
        )
      case 'ARCHIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={publishProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <PublishIcon width={24} height={24} />
            </button>
            <Link to={`/property/${id}/edit`}>
              <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
                <EditIcon/>
              </div>
            </Link>
          </div>
        )
      case 'REJECTED':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={archiveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <ArchiveIcon width={24} height={24} color="black" />
            </button>
            <Link to={`/property/${id}/edit`}>
              <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
                <EditIcon/>
              </div>
            </Link>
          </div>
        )
      default:
        return <></>
    }
  }, [status, id, publishProperty]);

  return items;
}

export default PropertyActionsAgent;
