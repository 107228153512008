import React from "react";
import { useForm } from "react-hook-form";

import { User } from "../../../common/types/dto/user";

import Input from "../../../common/components/Input/Input";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInput";
import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";

const UserProfile = ({ user }: { user: User }) => {
  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    legalName: user?.agentProfile?.legalName || '',
    address: user?.agentProfile?.address || '',
    ic: user?.agentProfile?.ic || '',
    companyEmail: user?.agentProfile?.email || '',
    companyPhone: user?.agentProfile?.phone || '',
  }

  const {
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  return (
    <CardWrapper className="p-[25px] min-h-[calc(100vh-180px)]">
      <div className="grid gap-[24px] ">
      <div className="grid grid-cols-[400px_400px] gap-[24px]">
        <Input
          label="Full Name"
          name="name"
          control={control}
          // placeholder="Enter your full name"
          disabled
        />
        <PhoneInput
          label="Phone number"
          name="phone"
          control={control}
          defaultCountry="CZ"
          // placeholder="Enter phone number"
          disabled
        />
        <Input
          label="Email"
          name="email"
          control={control}
          // placeholder="Email your email address"
          disabled
        />
      </div>
        <div className="grid grid-cols-[400px_400px] gap-[24px]">
          <Input
            label="Legal name"
            name="legalName"
            control={control}
            // placeholder="Enter legal name"
            disabled
          />
          <Input
            label="Address"
            name="address"
            control={control}
            // placeholder="Enter street, building number"
            disabled
          />
          <Input
            label="IČ"
            name="ic"
            control={control}
            // placeholder="Enter IČ"
            disabled
          />
          <Input
            label="Company Email"
            name="companyEmail"
            control={control}
            // placeholder="Enter email"
            disabled
          />
          <PhoneInput
            label="Company Phone number"
            name="companyPhone"
            control={control}
            defaultCountry="CZ"
            // placeholder="Enter company phone number"
            disabled
          />
        </div>
      </div>
    </CardWrapper>
  )
}

export default UserProfile;
