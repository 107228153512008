import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";

import { patchPropertyStatus } from "../../../api/services/property/requests";

import { IPatchPropertyStatus } from "../../../api/services/property/types";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

interface FormValues {
  duration: {
    label: string;
    value: number;
  };
}

const defaultValues = {
  duration: {
    label: "1 month",
    value: 1,
  }
}

const usePublishModalForm = ({ id }: { id: string; }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    control,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues,
  });

  const { mutateAsync } = useMutation(async ({ payload }: {
    payload: IPatchPropertyStatus,
  }) => {
    setLoader(true);
    const data = await patchPropertyStatus(id, payload);
    setLoader(false);
    return data;
  });

  const onSubmit: SubmitHandler<FormValues> = async () => {
    try {
      await mutateAsync({
        payload: {
          status: 'IN_MODERATE',
        },
      }, {
        onError: (error: any) => {
          setError(error?.response?.data?.message);
          setLoader(false);
        }
      });
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  }

  return {
    control,
    watch,
    handleSubmit: handleSubmit(onSubmit),
  };
}

export default usePublishModalForm;
