import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { Control } from "react-hook-form";
import { useDropzone } from "react-dropzone";

import AddImageIcon from "../../../common/assets/icons/AddImageIcon";
import PinIcon from "../../../common/assets/icons/PinIcon";

interface IPropertyEditPhotosProps {
  control: Control;
  gallery: any[];
  setGallery: Dispatch<SetStateAction<any[]>>;
  mainImageIndex: number;
  setMainImageIndex: Dispatch<SetStateAction<number>>;
  setDeleteGallery: Dispatch<SetStateAction<string[]>>;
}

const PropertyEditPhotos: FC<IPropertyEditPhotosProps> = ({
                                                            gallery,
                                                            setGallery,
                                                            mainImageIndex,
                                                            setMainImageIndex,
                                                            setDeleteGallery
                                                          }) => {

  const onDrop = useCallback((files: File[]) => {
    if (FileReader && files && files.length) {
      for (const file of files) {
        if(file.size >= 1048576) {
          alert(`File "${file.name}" is to big. Allowed files only under 1mb.`);
          continue;
        }
        const fr = new FileReader();
        fr.onload = function () {
          setGallery((prevState) => [...prevState, {file, src: fr.result, toUpload: true}]);
        }
        fr.readAsDataURL(file);
      }
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp', '.gif'],
    },
  });

  const handleDeleteClick = (image: any, index: number) => {
    if (!image.toUpload) setDeleteGallery((prevState) => [...prevState, image.id]);
    setGallery((prevState) => prevState.filter((_, i) => i !== index));
  }

  return (
    <div className="flex h-[170px] gap-[24px]">
      <div {...getRootProps()} className="aspect-[1] rounded-[10px] bg-[#f5f5f5] flex-center cursor-pointer">
        <input {...getInputProps()}/>
        <AddImageIcon/>
      </div>
      <div className="flex flex-1 gap-[24px] overflow-x-auto">
        {gallery.map((image, index) => (
          <div key={index} className="relative aspect-[2] group">
            {mainImageIndex === index && (
              <div
                className="absolute top-[6px] left-[6px] bg-[#eeeeee] text-[11px] text-[#666] font-medium px-[4px] py-[2px] rounded-[5px] flex-center gap-[4px] h-[21px]">
                <PinIcon/>
                <div>Main photo</div>
              </div>
            )}

            {mainImageIndex !== index && (
              <div
                className="absolute top-[6px] left-[6px] bg-[#eeeeee] text-[11px] text-[#666] font-medium px-[4px] py-[2px] rounded-[5px] hidden group-hover:flex justify-center items-center cursor-pointer h-[21px]"
                onClick={() => setMainImageIndex(index)}
              >
                <PinIcon/>
              </div>
            )}

            <div
              className="absolute top-[6px] right-[6px] text-[18px] text-[red] font-extrabold hidden group-hover:block cursor-pointer leading-[1]"
              onClick={() => handleDeleteClick(image, index)}
            >x
            </div>

            <img
              src={image.src}
              alt="property image"
              className="aspect-[2] rounded-[10px] object-cover w-full"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PropertyEditPhotos;
