import React from 'react';

import { patchPropertyArchive } from "../../../../api/services/property/requests";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";
import useGlobalLoaderStore from "../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../common/stores/useGlobalErrorStore";

import Button from "../../../../common/components/Button/Button";

interface ArchiveModalFormProps {
  id: string;
}

const ArchiveModalForm: React.FC<ArchiveModalFormProps> = ({ id }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const movePropertyToArchive = async () => {
    try {
      setLoader(true)
      await patchPropertyArchive(id);
      onClose();
      location?.reload();
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="grid gap-5 w-[572px] py-[10px]">
      <div className="text-[18px] text-center">Are you sure you want to cancel moderation?<br/>The listing will be moved
        to the archive.
      </div>
      <div className="flex-center gap-[46px]">
        <Button className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" onClick={movePropertyToArchive}>Move to
          archive</Button>
        <Button className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" onClick={onClose}>Cancel</Button>
      </div>
    </div>
  );
};

export default ArchiveModalForm;
