import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { postAuthImpersonate } from "../../api/services/auth/requests";
import { getUsersList } from "../../api/services/user/requests";

import { IPostAuthImpersonatePayload, IPostAuthImpersonateResponse } from "../../api/services/auth/types";
import { User } from "../../common/types/dto/user";

import useAuthStore from "../../common/stores/useAuthStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import Input from "../../common/components/Input/Input";

import MagnifyingGlass from "../../common/assets/icons/MagnifyingGlass";

const USERS_PER_PAGE = 20;

const AgentsPage = () => {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const { setUser, setImpersonationToken } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { control, watch } = useForm<{
    search: string,
  }>({
    defaultValues: {
      search: "",
    }
  });

  const search = watch("search");

  const { data } = useQuery(["agentsList", search.length > 2 ? search : '', page], async () => {
    const payload = {
      role: 'agent',
      ...search.length > 2 && { email: search },
      page,
      limit: USERS_PER_PAGE,
    };

    setLoader(true);
    const data = await getUsersList(payload);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    },
  });

  const handleImpersonate = async (agent: User) => {
    const payload: IPostAuthImpersonatePayload = {
      userId: agent.id,
    };
    try {
      const { accessToken }: IPostAuthImpersonateResponse = await postAuthImpersonate(payload);

      setImpersonationToken(accessToken);
      setUser(agent)
      navigate('/');
    } catch (error: any) {
      setError(error?.response?.data?.message || '')
    }
  }

  const agentsMap = data?.items?.map((agent: User) => {
    const { id, name, email, avatar, createdAt } = agent
    return (
      <React.Fragment key={id}>
        <div className="flex items-center gap-[10px]">
          <div className="h-[36px] w-[36px]">
            {avatar?.url ? (
              <img
                src={avatar.url}
                width={36}
                height={36}
                alt="user icon"
                className="h-[36px] w-[36px] rounded-[12px] object-cover"
              />
            ) : (
              <div className="flex-center rounded-[12px] w-[36px] h-[36px] bg-blue text-white">{name?.charAt(0)}</div>
            )}
          </div>
          <div className="leading-[1.2] text-[14px]">
            <div className="text-[#2D3748] font-bold">{name}</div>
            <div className="text-[#718096]">{email}</div>
          </div>
        </div>
        {/*<div className="text-center text-[14px] text-[#666666] leading-[1.2]">*/}
        {/*  <div>43 for sell</div>*/}
        {/*  <div>14 for rent</div>*/}
        {/*</div>*/}
        <div className="text-center text-[14px] text-[#2D3748] font-bold">{
          new Intl.DateTimeFormat('default', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          }).format(new Date(createdAt)).replace(/\./g, '/')
        }</div>
        <div>
          <button onClick={() => handleImpersonate(agent)} className="text-[12px] text-[#006CFB] font-bold whitespace-nowrap">Impersonate</button>
        </div>
        <div className="col-span-3 border-t border-[#E2E8F0]" />
      </React.Fragment>
    )
  })

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <div className="flex items-center justify-between gap-[20px]">
        <div />
        <div className="relative w-[317px]">
          <Input
            control={control}
            type="text"
            name="search"
            classnames="flex items-center w-full h-[38px] pl-[50px] pr-[10px] rounded-[8px] bg-[#F4F4F4]"
            placeholder="Search..."
            unstyled
          />
          <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2" /></div>
        </div>
      <div className="mt-[25px]">
        <CardWrapper className="p-[30px]">
          <div className="text-[#11142d] text-[18px] font-semibold mb-3">Agents</div>
          <div className="grid grid-cols-[1fr_130px_80px] gap-x-[50px] gap-y-3 items-center mb-6">
            <div className="text-[10px] text-[#A0AEC0] uppercase font-bold">Agent</div>
            {/*<div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">Property count</div>*/}
            <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">Date of registration</div>
            <div/>
            <div className="col-span-3 border-t border-[transparent]" />
            {agentsMap}
          </div>
        </CardWrapper>
      </div>
    </div>
  )
}

export default AgentsPage;
