import React, { useCallback, useMemo } from "react";

import { patchPropertyApprove } from "../../../../api/services/property/requests";

import { PropertyStatus } from "../../../../common/types/dto/property";

import useGlobalLoaderStore from "../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import RejectPropertyModal from "../RejectPropertyModal/RejectPropertyModal";

import CheckIcon from "../../../../common/assets/icons/CheckIcon";
import CrossIcon from "../../../../common/assets/icons/CrossIcon";
import ThrashCan from "../../../../common/assets/icons/ThrashCan";

interface PropertyActionsAdminProps {
  id?: string;
  status: PropertyStatus;
}

//todo: implement actions
const PropertyActionsAdmin: React.FC<PropertyActionsAdminProps> = ({ id, status }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  if (!id) return <></>;

  const approveProperty = useCallback(async () => {
    try {
      setLoader(true)
      await patchPropertyApprove(id);
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }, [id]);

  const rejectProperty = useCallback(() => {
    setChildren(<RejectPropertyModal id={id} />)
    setIsOpen(true);
  }, [id]);

  const deleteProperty = useCallback(() => {
    alert('not implemented')
  }, [id]);

  const items = useMemo(() => {
    switch (status) {
      case 'IN_MODERATE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={rejectProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CrossIcon width={24} height={24} color="red" />
            </button>
            <button onClick={approveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CheckIcon width={24} height={24} color={'green'} />
            </button>
          </div>
        )
      case 'ACTIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={deleteProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <ThrashCan width={24} height={24} color="orange" />
            </button>
            <button onClick={rejectProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CrossIcon width={24} height={24} color="#E53E3E" />
            </button>
          </div>
        )
      default:
        return <></>
    }
  }, [status, approveProperty, rejectProperty, deleteProperty])

  return (
    <>
      {items}
    </>
  )
}

export default PropertyActionsAdmin;
